import MainLayout from '~/components/layouts/MainLayout'
import { AuthorProps } from '~/templates/blog/Author'
import { cx } from '~/utils'
import BlogPostList from '~/views/Blog/components/BlogPostList'
import AuthorHead from '~/views/Blog/views/Author/components/AuthorHead'
import AuthorsPreview from '~/views/Blog/views/Author/components/AuthorsPreview'

import * as containerStyles from './Author.module.scss'

const Author = ({ data }: AuthorProps) => {
  const articles = data.allStrapiArticle.edges.map((article) => article.node)

  return (
    <MainLayout isStaticChat>
      <div className={containerStyles.authorPage}>
        <AuthorHead data={data} />

        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <h2 className={containerStyles.authorPage__title}>
                List of author’s articles
              </h2>
            </div>
          </div>

          <div className={cx(containerStyles.authorPage__postsRow, 'row')}>
            <BlogPostList data={articles} onlyPosts />
          </div>
        </div>

        <AuthorsPreview data={data} />
      </div>
    </MainLayout>
  )
}
export default Author
