import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import AuthorSocial from '~/views/Blog/views/Author/components/AuthorSocial'

import * as containerStyles from './AuthorHead.module.scss'

const AuthorHead = ({ data }) => {
  const { name, slug, position, description, avatar, about } =
    data.strapiAuthor as WithRequired<
      Queries.STRAPI_AUTHOR,
      'name' | 'position' | 'avatar'
    >
  const CEO = 'dmytro'
  const BusinessPartner = 'natalya'

  const image = getImage(fileToImageLikeData(avatar.localFile))

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.authorHead__wrapper}>
            <div className={containerStyles.authorHead__flexWrapper}>
              <div className={containerStyles.authorHead__photo}>
                <GatsbyImage
                  image={image!}
                  alt={`${name} ${position} | Codica`}
                  title={`${name} ${position}`}
                  objectFit="contain"
                  className={containerStyles.authorHead__photo}
                />
              </div>
              <div>
                <h1 className={containerStyles.authorHead__name}>{name}</h1>
                <div className={containerStyles.authorHead__position}>
                  {position}
                </div>
              </div>
            </div>

            <div className={containerStyles.authorHead__socialWrapper}>
              <div className={containerStyles.authorHead__description}>
                {description}
              </div>
              {(`${slug}` === CEO || `${slug}` === BusinessPartner) && (
                <AuthorSocial authorSlug={slug} />
              )}
            </div>
          </div>
        </div>

        {about && (
          <div className="col-12">
            <div className={containerStyles.authorHead__aboutAuthorWrapper}>
              <div className={containerStyles.authorHead__aboutAuthorTitle}>
                About author
              </div>
              <div
                className={containerStyles.authorHead__aboutAuthorDescription}
              >
                {about}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AuthorHead
