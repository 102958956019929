import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'

import SEO from '~/components/shared/Seo'
import { AllStrapiArticle, QueryResult, StrapiAuthor } from '~/types/graphql'
import { WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import AuthorView from '~/views/Blog/views/Author'

export type AuthorProps = QueryResult<StrapiAuthor & AllStrapiArticle>

export const Head = ({ data }: AuthorProps) => {
  const { name, slug, position, description, avatar } =
    data.strapiAuthor as WithRequired<
      Queries.STRAPI_AUTHOR,
      'name' | 'position' | 'avatar'
    >

  return (
    <SEO
      title={`${name}, ${position} and Author at Codica Blog`}
      description={description}
      urlImage={getSrc(fileToImageLikeData(avatar.localFile)) as string}
      url={`https://www.codica.com/blog/author/${kebabCase(slug || '')}/`}
      authorSlug={slug as string}
      authorName={name}
      isPerson
    />
  )
}

const Author = (props: AuthorProps) => <AuthorView {...props} />

export default Author

export const query = graphql`
  query AuthorContent($name: String, $published: [Boolean]) {
    strapiAuthor(name: { eq: $name }) {
      name
      slug
      about
      position
      description
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
          }
        }
      }
      articles {
        id
        title
        url
      }
    }

    allStrapiAuthor {
      edges {
        node {
          slug
          name
          position
          articles {
            active
          }
          avatar {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
        }
      }
    }

    allStrapiArticle(
      sort: { modified_date: DESC }
      filter: {
        active: { eq: true }
        authors: { elemMatch: { name: { eq: $name } } }
        published: { in: $published }
      }
    ) {
      edges {
        node {
          publication_date(formatString: "DD MMM YYYY")
          modified_date(formatString: "DD MMM YYYY")
          id
          url
          strapi_id
          views
          preview_image_alt
          category {
            name
          }
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
          title
          meta_description
          published
        }
      }
    }
  }
`
