// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "dW_G4";
export var authorHead__aboutAuthorTitle = "dW_G3";
export var authorHead__aboutAuthorWrapper = "dW_G2";
export var authorHead__description = "dW_G0";
export var authorHead__flexWrapper = "dW_GW";
export var authorHead__name = "dW_GY";
export var authorHead__photo = "dW_GX";
export var authorHead__position = "dW_GZ";
export var authorHead__socialWrapper = "dW_G1";
export var authorHead__wrapper = "dW_GV";